import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../utils/Context/DataContext";
import { Link, useParams, useOutletContext } from "react-router-dom";
import { api } from "../../utils/Api";
import Skeleton from "react-loading-skeleton";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Menu,
  MenuItem,
  Modal,
} from "@material-ui/core";
import { AppConfig } from "../../config";
import VisibilitySensor from "react-visibility-sensor";
import { AppHelper } from "../../utils/Helper";
import * as Scroll from "react-scroll";
import SwipeableBottomSheet from "@geomatico/react-swipeable-bottom-sheet";
import toast from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";

export default function ManageProductsComponent(props: any) {
  const [id_value, SerIdValue] = useState(useParams());
  const [is_loading, SetIsLoading] = useState(true);
  const [main_data, SetMainData]: any = useState({});
  const [selected_outlet, SetSelectedOutlet]: any = useState({});
  const [selected_channel, SetSelectedChannel]: any = useState({});
  const [image_base_url, SetImgBaseUrl]: any = useState("");
  const [open_collection_menu, SetOpenCollectionMenu]: any = useState(null);
  const [selected_index, SetSelectedIndex] = useState(1);
  const [bottom_sheet_data, SetBottomSheetData] = useState({
    is_open: false,
    data: {},
  });
  const [uncheck_radio_value, SetUncheckRadioValue] = useState({
    checked_value: "",
    other_value: "",
  });
  const [more_has_more, SetMoreHasMore] = useState(true);
  const [have_more_page_no, SetHaveMorePageNo] = useState(1);

  useEffect(() => {
    console.log("ManageProductsComponent on mount:", props);
    SetMainData(props.main_data);
  }, []);

  async function get_collection_product(
    key: any,
    get_data: any,
    have_more_data: any
  ) {
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        outlet_id: props.selected_outlet.id,
        channel_id: props.selected_channel.id,
      },
    };
    if (key == "More") {
      pass_data.get.page = have_more_data.page_no;
    } else {
      pass_data.get.collection_id = get_data.collection_id;
    }
    let response: any = await api("/brand/get_collection_products", pass_data);
    console.log("/brand/get_collection_product response :", response.response);
    if (response.status_code == 200) {
      if (key != "More") {
        SetMainData((prevalue: any) => {
          prevalue[key].is_viewed = true;
          prevalue[key].show_skeleton = false;
          prevalue[key].products = response.response.data;
          return { ...prevalue };
        });
      } else {
        if (response.response.data.length > 0) {
          SetMainData((prevalue: any) => {
            prevalue[key].is_viewed = true;
            prevalue[key].show_skeleton = false;
            response.response.data.map((ele: any) => {
              prevalue[key].products.push(ele);
            });
            return { ...prevalue };
          });
          SetHaveMorePageNo((page_prevalule) => {
            page_prevalule = page_prevalule + 1;
            return page_prevalule;
          });
        } else {
          SetMoreHasMore(false);
        }
      }

      SetImgBaseUrl(response.response.image_base_url);
    }
  }

  async function available_submit(
    get_data: any,
    type: any,
    p_ele: any,
    p_index: any,
    key: any
  ) {
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        outlet_id: props.selected_outlet.id,
        channel_id: props.selected_channel.id,
        collection_page: 1,
        // bp_ids: [],
      },
      // post: {
      //   data: {
      //     outlet_channel_product_variants_available: get_data,
      //   },
      // },
      post: {},
    };
    let bp_ids_value = [];
    if (type == "product") {
      pass_data.post.data = get_data;
      bp_ids_value.push(p_ele.brand_product_id);
      pass_data.get.bp_ids = encodeURIComponent(JSON.stringify(bp_ids_value));
    }
    if (type == "product_group") {
      pass_data.post.data = get_data;
      bp_ids_value.push(p_ele.brand_product_group_id);
      pass_data.get.bpg_ids = encodeURIComponent(JSON.stringify(bp_ids_value));
    }
    if (id_value?.outlet_id) {
      pass_data.get.outlet = id_value?.outlet_id;
    }
    console.log("available_submit pass_data :", pass_data);
    // let response: any = await api(
    //   "/brand/save_outlet_product_variants_v2",
    //   pass_data,
    //   false,
    //   false,
    //   "raw_data"
    // );
    let response: any;
    if (type == "product") {
      response = await api(
        "/brand/save_outlet_product_variants_v2",
        pass_data,
        false,
        false,
        "raw_data"
      );
    }

    if (type == "product_group") {
      response = await api(
        "/brand/save_outlet_channel_products_v2",
        pass_data,
        false,
        false,
        "raw_data"
      );
    }

    if (response.status_code == 200) {
      toast.success("Update Successful");
      SetMainData((prevalue: any) => {
        prevalue[key].products[p_index] = response.response.data[0];
        return { ...prevalue };
      });
    } else {
      toast.error("Somthing went ");
    }
    SetBottomSheetData((prevalue: any) => {
      prevalue.is_open = false;
      prevalue.data = {};
      return { ...prevalue };
    });
    SetUncheckRadioValue({
      checked_value: "",
      other_value: "",
    });
  }

  function handleOnView(key: any, isVisible: any) {
    if (isVisible) {
      SetMainData((prevalue: any) => {
        if (prevalue[key].is_viewed == false) {
          let have_more_data: any = {};
          if (key == "More") {
            SetHaveMorePageNo((page_prevale) => {
              have_more_data.page_no = page_prevale;
              return page_prevale;
            });
          }
          get_collection_product(key, prevalue[key], have_more_data);
        }
        return { ...prevalue };
      });
    }
  }

  function menuClose() {
    SetOpenCollectionMenu(null);
  }

  function on_available_change(event: any, p_ele: any, p_index: any, key: any) {
    if (event.target.checked) {
      console.log("call api");
      if (p_ele.type == "product") {
        let push_value: any = {
          outlet_channel_product_variants_available: {
            [p_ele.save_id]: {
              value: 1,
            },
          },
        };
        console.log("push_value :", push_value);
        available_submit(push_value, p_ele.type, p_ele, p_index, key);
      }
      if (p_ele.type == "product_group") {
        let push_value: any = {
          outlet_channel_products_available: {
            [p_ele.save_id]: {
              value: 1,
            },
          },
          outlet_channel_variants_available: {},
        };
        console.log("push_value :", push_value);
        available_submit(push_value, p_ele.type, p_ele, p_index, key);
      }
    } else {
      console.log("open bottom sheet");
      let send_data1 = {
        p_ele: p_ele,
        p_index: p_index,
        key: key,
      };
      if (
        p_ele.channel_unavailable.hasOwnProperty("next_slot") &&
        p_ele.channel_unavailable.next_slot != ""
      ) {
        SetBottomSheetData((prevalue: any) => {
          prevalue.is_open = true;
          prevalue.data = send_data1;
          return { ...prevalue };
        });
      }
    }
  }

  function get_uncheck_value_data(p_ele: any, p_index: any, key: any) {
    let return_data;
    if (p_ele.channel_assigned == "unchecked") {
      return_data = "Not Assigned";
    }
    if (p_ele.channel_unavailable.value == 1) {
      return_data = "";
    }
    if (p_ele.channel_unavailable.value == 2) {
      return_data = "Unavailable Permanent";
    }
    if (p_ele.channel_unavailable.value == 3) {
      if (p_ele.channel_unavailable.hasOwnProperty("hours")) {
        return_data =
          "Available after " + p_ele.channel_unavailable.hours + "Hours";
      } else {
        if (p_ele.channel_unavailable.hasOwnProperty("time")) {
          return_data = "Available At " + p_ele.channel_unavailable.time;
        } else {
          return_data = "";
        }
      }
    }
    if (p_ele.channel_unavailable.value == 4) {
      // return_data = "Unavailable till Next Available Slot";
      if (p_ele.channel_unavailable.hasOwnProperty("days")) {
        return_data =
          "Available after " + p_ele.channel_unavailable.days + " Days";
      } else {
        if (p_ele.channel_unavailable.hasOwnProperty("time")) {
          return_data = "Available At " + p_ele.channel_unavailable.time;
        } else {
          return_data = "";
        }
      }
    }
    if (p_ele.channel_unavailable.value == 5) {
      if (p_ele.channel_unavailable.hasOwnProperty("time")) {
        return_data = "Available At " + p_ele.channel_unavailable.time;
      } else {
        return_data = "";
      }
    }
    return return_data;
  }

  return (
    <div className="col-md-6">
      {/* collection list */}
      <div className="px-sm-1 px-md-0 prod_collection">
        {Object.keys(main_data).map((ele: any, index: any) => (
          <div className="row md-ps-0 sm-px-3">
            <VisibilitySensor
              partialVisibility={true}
              // offset={{ top: 10 }}
              // minTopValue={10}
              active={true}
              onChange={(isVisible: any) => {
                if (isVisible) {
                  handleOnView(ele, isVisible);
                }
              }}
            >
              <div
                className="card px-2 py-1 mb-3"
                id={main_data[ele].collection_id}
              >
                <Accordion className="px-0" defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={
                      <img
                        src={
                          AppConfig.CDN_Media_url +
                          "43fd67bad621bf93fac936e25b16c7aa.svg"
                        }
                        className="w-10px"
                      />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="px-1"
                  >
                    <h5 className="text-black mb-0 fw-bold">{ele}</h5>
                    {/* <p>{}</p> */}
                  </AccordionSummary>
                  <div className="row align-items-center border-bottom"></div>
                  <AccordionDetails className="">
                    <div className="w-100">
                      {main_data[ele].show_skeleton ? (
                        [1, 2, 3, 4, 5].map((sk_ele) => (
                          <div className="mt-3 col-12">
                            <Skeleton width={"100%"} height={80} />
                          </div>
                        ))
                      ) : main_data[ele].products.length > 0 ? (
                        ele != "More" ? (
                          main_data[ele].products.map(
                            (p_ele: any, p_index: any) => (
                              <div className="w-100  pt-3 pb-2 border-bottom">
                                <div className="row ">
                                  <div
                                    className="col-8 ps-0 cursor"
                                    onClick={() => {}}
                                  >
                                    <div className="d-flex align-items-center pb-2">
                                      <img
                                        src={
                                          AppConfig.CDN_Image_url +
                                          "e301ce3af353aab421a2f76c898a439d.png"
                                        }
                                        className="type-img"
                                      />
                                      <p className="ps-2 text-dark ">
                                        {p_ele?.type == "product_group"
                                          ? "Product Group"
                                          : "Product"}
                                      </p>
                                    </div>

                                    {/* <h6 className="pt-1 mb-1 oneline"> */}
                                    <h6 className="pt-1 mb-1">
                                      {p_ele?.name}
                                    </h6>

                                    <p className="marg celias-font h-33px">
                                      {/* className= {g_props.data.description != null ? "marg celias-font h-33px" : "h-33px" }> */}
                                      {p_ele?.description}
                                    </p>
                                    {/* price */}
                                    {/* <p className="pt-2 h-40px">
                                      <b className="text-dark fs-7 celias-font">
                                        ₹20
                                      </b>
                                    </p>
                                    <p className="strike">
                                      ₹10
                                    </p> */}
                                  </div>
                                  <div className="col-4 p-0">
                                    <div className="near-img">
                                      {p_ele.hasOwnProperty("images") &&
                                      p_ele.images.length > 0 ? (
                                        <img
                                          src={AppHelper.imageSrc(
                                            image_base_url,
                                            p_ele.images[0],
                                            { format: "webp", width: 100 }
                                          )}
                                          className="near-img  full"
                                        />
                                      ) : (
                                        <img
                                          src={
                                            AppConfig.CDN_Image_url +
                                            "33c67a26ab58ada3ee31b91f7efb32bb.png"
                                          }
                                          className="near-img  full"
                                        />
                                      )}
                                    </div>
                                  </div>

                                  {/* <div className="col-3 p-0 ">
                                    {p_ele.hasOwnProperty("images") &&
                                    p_ele.images.length > 0 ? (
                                      <img
                                        src={AppHelper.imageSrc(
                                          image_base_url,
                                          p_ele.images[0],
                                          { format: "webp", width: 100 }
                                        )}
                                        className="prod-timing-image"
                                      />
                                    ) : (
                                      <img
                                        src={
                                          AppConfig.CDN_Image_url +
                                          "33c67a26ab58ada3ee31b91f7efb32bb.png"
                                        }
                                        className="prod-timing-image"
                                      />
                                    )}
                                  </div>
                                  <div className="col-9 p-2">
                                    <div className="pb-1 d-flex align-items-center">
                                      <p className="ps-1 text-black extra-small">
                                        {p_ele?.category}
                                      </p>
                                    </div>
                                    <p className="text-dark fw-bold item-center">
                                      {p_ele?.name}
                                    </p>
                                    {p_ele?.description != null ? (
                                      <div className="pt-2">
                                        <p className="text-gray extra-small">
                                          {p_ele?.description}
                                        </p>
                                      </div>
                                    ) : null}
                                  </div> */}
                                </div>
                                {/* <div className="bg-fff shadow radius p-2 my-2">
                                  <div className="row align-items-center">
                                    <div className="col-5 form-check form-switch px-0">
                                      <p className="v-small text-dark pb-1 fw-600">
                                        Product Available
                                      </p>
                                      <input
                                        type="checkbox"
                                        className="form-check-input ms-0"
                                        checked={
                                          p_ele.channel_unavailable.value == 1
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          p_ele.channel_assigned == "unchecked"
                                            ? true
                                            : false
                                        }
                                        onChange={(event) => {
                                          console.log("switch on change");
                                          on_available_change(
                                            event,
                                            p_ele,
                                            p_index,
                                            ele
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="col-7 pe-0">
                                      {p_ele.channel_unavailable.value != 1 ? (
                                        <div className="text-end">
                                          <p className="v-small text-dark pb-1 fw-600">
                                            Status
                                          </p>
                                          <p className=" small text-gray">
                                            {get_uncheck_value_data(
                                              p_ele,
                                              p_index,
                                              ele
                                            )}
                                          </p>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div> */}
                                <div className="d-flex align-items-center mt-2">
                                  <div className="">
                                    {p_ele.channel_unavailable.value != 1 ? (
                                      <div className="text-end">
                                        <p className=" small text-gray">
                                          {get_uncheck_value_data(
                                            p_ele,
                                            p_index,
                                            ele
                                          )}
                                        </p>
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className=" ms-auto form-check form-switch mt-4">
                                    {/* <div className=" ms-auto mt-4"> */}
                                    {/* <label className="pure-material-switch" htmlFor={"avail"+p_index}> */}
                                    <input
                                      type="checkbox"
                                      id={"avail" + p_index}
                                      className="form-check-input"
                                      // className="matching-group-switch"
                                      checked={
                                        p_ele.channel_unavailable.value == 1
                                          ? true
                                          : false
                                      }
                                      disabled={
                                        p_ele.channel_assigned == "unchecked"
                                          ? true
                                          : false
                                      }
                                      onChange={(event) => {
                                        console.log("switch on change");
                                        on_available_change(
                                          event,
                                          p_ele,
                                          p_index,
                                          ele
                                        );
                                      }}
                                    />
                                    {/* </label> */}
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <InfiniteScroll
                            // className="row"
                            dataLength={main_data[ele].products.length}
                            next={() => {
                              console.log("calling from next");
                              let have_more_data: any = {};
                              SetHaveMorePageNo((page_prevale) => {
                                have_more_data.page_no = page_prevale;
                                return page_prevale;
                              });
                              get_collection_product(
                                ele,
                                main_data[ele],
                                have_more_data
                              );
                            }}
                            hasMore={more_has_more}
                            loader={
                              <div className="d-flex align-items-center justify-content-center text-center p-5">
                                <div className="lds-dual-ring"></div>
                                <span className=" small ps-2">
                                  Just a second ...
                                </span>
                              </div>
                            }
                            endMessage={
                              <div className="d-flex align-items-center justify-content-center text-center p-3">
                                <span className=" small">
                                  --- All Products Loaded ---
                                </span>
                              </div>
                            }
                          >
                            {main_data[ele].products.map(
                              (p_ele: any, p_index: any) => (
                                <div className="w-100  pt-3 pb-2 border-bottom">
                                  <div className="row ">
                                    <div
                                      className="col-8 ps-0 cursor"
                                      onClick={() => {}}
                                    >
                                      <div className="d-flex align-items-center pb-2">
                                        <img
                                          src={
                                            AppConfig.CDN_Image_url +
                                            "e301ce3af353aab421a2f76c898a439d.png"
                                          }
                                          className="type-img"
                                        />
                                        <p className="ps-2 text-dark ">
                                          {p_ele?.type == "product_group"
                                            ? "Product Group"
                                            : "Product"}
                                        </p>
                                      </div>

                                      {/* <h6 className="pt-1 mb-1 oneline"> */}
                                      <h6 className="pt-1 mb-1">
                                        {p_ele?.name}
                                      </h6>

                                      <p className="marg celias-font h-33px">
                                        {/* className= {g_props.data.description != null ? "marg celias-font h-33px" : "h-33px" }> */}
                                        {p_ele?.description}
                                      </p>

                                      {/* price */}
                                      {/* <p className="pt-2 h-40px">
                                        <b className="text-dark fs-7 celias-font">
                                          ₹20
                                        </b>
                                      </p>

                                      <p className="strike">
                                        ₹10
                                      </p> */}
                                    </div>
                                    <div className="col-4 p-0">
                                      <div className="near-img">
                                        {p_ele.hasOwnProperty("images") &&
                                        p_ele.images.length > 0 ? (
                                          <img
                                            src={AppHelper.imageSrc(
                                              image_base_url,
                                              p_ele.images[0],
                                              { format: "webp", width: 100 }
                                            )}
                                            className="near-img  full"
                                          />
                                        ) : (
                                          <img
                                            src={
                                              AppConfig.CDN_Image_url +
                                              "33c67a26ab58ada3ee31b91f7efb32bb.png"
                                            }
                                            className="near-img  full"
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="bg-fff shadow radius p-2 my-2">
                                    <div className="row align-items-center">
                                      <div className="col-5 form-check form-switch px-0">
                                        <p className="v-small text-dark pb-1 fw-600">
                                          Product Available
                                        </p>
                                        <input
                                          type="checkbox"
                                          className="form-check-input ms-0"
                                          checked={
                                            p_ele.channel_unavailable.value == 1
                                              ? true
                                              : false
                                          }
                                          disabled={
                                            p_ele.channel_assigned ==
                                            "unchecked"
                                              ? true
                                              : false
                                          }
                                          onChange={(event) => {
                                            console.log("switch on change");
                                            on_available_change(
                                              event,
                                              p_ele,
                                              p_index,
                                              ele
                                            );
                                          }}
                                        />
                                      </div>
                                      <div className="col-7 pe-0">
                                        {p_ele.channel_unavailable.value !=
                                        1 ? (
                                          <div className="text-end">
                                            <p className="v-small text-dark pb-1 fw-600">
                                              Status
                                            </p>
                                            <p className=" small text-gray">
                                              {get_uncheck_value_data(
                                                p_ele,
                                                p_index,
                                                ele
                                              )}
                                            </p>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div> */}
                                  <div className="d-flex align-items-center mt-2">
                                    <div className="">
                                      {p_ele.channel_unavailable.value != 1 ? (
                                        <div className="text-end">
                                          <p className=" small text-gray">
                                            {get_uncheck_value_data(
                                              p_ele,
                                              p_index,
                                              ele
                                            )}
                                          </p>
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className=" ms-auto form-check form-switch mt-4">
                                      {/* <div className=" ms-auto mt-4"> */}
                                      {/* <label className="pure-material-switch" htmlFor={"avail"+p_index}> */}
                                      <input
                                        type="checkbox"
                                        id={"avail" + p_index}
                                        className="form-check-input"
                                        // className="matching-group-switch"
                                        checked={
                                          p_ele.channel_unavailable.value == 1
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          p_ele.channel_assigned == "unchecked"
                                            ? true
                                            : false
                                        }
                                        onChange={(event) => {
                                          console.log("switch on change");
                                          on_available_change(
                                            event,
                                            p_ele,
                                            p_index,
                                            ele
                                          );
                                        }}
                                      />
                                      {/* </label> */}
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                            {/* <div className="w-100 border-bottom">
                                  <div className="row mt-2 mb-2 ">
                                    <div className="col-3 p-0 ">
                                      {p_ele.hasOwnProperty("images") &&
                                      p_ele.images.length > 0 ? (
                                        <img
                                          src={AppHelper.imageSrc(
                                            image_base_url,
                                            p_ele.images[0],
                                            { format: "webp", width: 100 }
                                          )}
                                          className="prod-timing-image"
                                        />
                                      ) : (
                                        <img
                                          src={
                                            AppConfig.CDN_Image_url +
                                            "33c67a26ab58ada3ee31b91f7efb32bb.png"
                                          }
                                          className="prod-timing-image"
                                        />
                                      )}
                                    </div>
                                    <div className="col-9 p-2">
                                      <div className="pb-1 d-flex align-items-center">
                                        <p className="ps-1 text-black extra-small">
                                          {p_ele?.category}
                                        </p>
                                      </div>
                                      <p className="text-dark fw-bold item-center">
                                        {p_ele?.name}
                                      </p>
                                      {p_ele?.description != null ? (
                                        <div className="pt-2">
                                          <p className="text-gray extra-small">
                                            {p_ele?.description}
                                          </p>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-6 form-check form-switch">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        disabled={
                                          p_ele.channel_assigned == "unchecked"
                                            ? true
                                            : false
                                        }
                                        checked={
                                          p_ele.channel_unavailable.value == 1
                                            ? true
                                            : false
                                        }
                                        onChange={(event) => {
                                          console.log("switch on change");
                                          on_available_change(
                                            event,
                                            p_ele,
                                            p_index,
                                            ele
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="col-6">
                                      <p className="mt-2 v-small text-gray">
                                        {get_uncheck_value_data(
                                          p_ele,
                                          p_index,
                                          ele
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div> */}
                          </InfiniteScroll>
                        )
                      ) : (
                        <div className="align-center">
                          <p>No Products</p>
                        </div>
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </VisibilitySensor>
          </div>
        ))}
      </div>
      {/* Menu */}
      <div className={"bottom_data"}>
        <div className="md-none center">
          {Object.keys(main_data).length > 0 && open_collection_menu == null ? (
            <div className={"center bg-none menu-close-btn"}>
              <button
                className="btn d-flex bg-dark align-center"
                onClick={(e) => {
                  SetOpenCollectionMenu(e.currentTarget);
                }}
              >
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "e4f42080d124bbd465d7d59837afcab0.svg"
                  }
                  className="w20px"
                />
                <h6 className="ps-1 mb-0">Menu</h6>
              </button>
            </div>
          ) : null}
        </div>
      </div>
      {/* menu open */}
      {open_collection_menu != null ? (
        <div className="food-menu-open">
          {/* floting Menu */}
          {/* <Menu
            anchorEl={open_collection_menu}
            keepMounted
            open={Boolean(open_collection_menu)}
            onClose={menuClose}
            elevation={0}
            getContentAnchorEl={null}
            transitionduration={0}
            anchororigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformorigin={{
              vertical: "top",
              horizontal: "center",
            }}
            className="food-menu-open"
          > */}
          <Modal
            open={Boolean(open_collection_menu)}
            className="px-md-2 px-sm-0"
          >
            <div className="bottom_data">
              <div
                className="center l-0 mb-5" //     ? //   footer_open_cls == "footer_visible" // {
                // : "footer-hidden-center mb-5"
                // }
              >
                <div className="bg-white radius mb-3 w-100 w-md-80">
                  <div className="menu-card p-3 border-top-radius w-md-80">
                    <h5 className="fw-bold menu-center px-2">
                      <span className="px-2">Menu</span>
                    </h5>
                  </div>
                  <div className="scroll60">
                    {Object.keys(main_data)?.map((items: any, index) => (
                      <MenuItem key={index} selected={index === selected_index}>
                        <Scroll.Link
                          className="flex"
                          activeclassName="scroll-active"
                          to={main_data[items].collection_id}
                          spy={true}
                          smooth={true}
                          offset={-200}
                          duration={500}
                          onClick={(event: any) => {
                            SetSelectedIndex(index);
                            menuClose();
                          }}
                        >
                          <p className="py-2 cursor scroll-active ">{items}</p>
                        </Scroll.Link>
                      </MenuItem>
                    ))}
                  </div>
                  <div className="bottom_data">
                    <div
                      className="center l-0" //     ? //   footer_open_cls == "footer_visible" // {
                      //     : "footer-hidden-center"
                      // }
                    >
                      <button
                        className="btn d-flex bg-dark align-center"
                        onClick={(e) => {
                          menuClose();
                        }}
                      >
                        <img
                          src={
                            AppConfig.CDN_Media_url +
                            "b960e2388e4e92aa031a5adf38d4a31f.svg"
                          }
                          className="w20px"
                        />

                        {/* <Ionicons name="close" size={18} color="white" /> */}
                        <h6 className="ps-2 mb-0 text-white">Close</h6>
                      </button>
                    </div>
                  </div>
                  {/* <div className="bottom-data">
              <div className="center-menu center l-0">
                <button
                  className="btn flex bg-dark align-center"
                  onClick={(e) => {
                    menuClose();
                    console.log("Menu close clicked!");
                  }}
                >
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "b960e2388e4e92aa031a5adf38d4a31f.svg"
                    }
                    className="w-20"
                  />
                  <h6 className="ps-2 mb-0 text-white">Close</h6>
                </button>
              </div>
              </div> */}
                </div>
              </div>
            </div>
          </Modal>
          {/* </Menu> */}
        </div>
      ) : null}
      {/* bottom sheet available slot */}
      {bottom_sheet_data.is_open ? (
        <div className="out_of_stock_swipe">
          <SwipeableBottomSheet
            overflowHeight={0}
            overlay={true}
            style={{ zIndex: 1300 }}
            open={bottom_sheet_data.is_open}
            onClose={() => {
              SetBottomSheetData((prevalue: any) => {
                prevalue.is_open = false;
                prevalue.data = {};
                return { ...prevalue };
              });
              SetUncheckRadioValue({
                checked_value: "",
                other_value: "",
              });
            }}
            onChange={() => {
              // SetFilterOpen(false)
              SetBottomSheetData((prevalue: any) => {
                prevalue.is_open = false;
                prevalue.data = {};
                return { ...prevalue };
              });
              SetUncheckRadioValue({
                checked_value: "",
                other_value: "",
              });
            }}
          >
            <div
              className="bg-fff radius-top"
              style={{ minHeight: "500px", maxHeight: "90vh" }}
            >
              <div className="">
                <div className="w-100 center">
                  <hr className="hr-drawer" />
                </div>
                <div className="p-3">
                  <h6 className="fs-5 fw-600">Set time to make out of stock</h6>
                </div>
              </div>
              <div className="p-3">
                <div className="mb-3 row">
                  {/* 2 hours */}
                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"2 Hours"}
                      checked={
                        uncheck_radio_value.checked_value == "2 Hours"
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        console.log("name event :", event);
                        SetUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p className="capitalize">2 Hours</p>
                  </label>
                  {/* 4 Hours */}
                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"4 Hours"}
                      checked={
                        uncheck_radio_value.checked_value == "4 Hours"
                          ? true
                          : false
                      }
                      // value={ele.id}
                      onChange={(event) => {
                        SetUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p className="capitalize">4 Hours</p>
                  </label>
                  {/* 1 Day */}
                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"1 Day"}
                      checked={
                        uncheck_radio_value.checked_value == "1 Day"
                          ? true
                          : false
                      }
                      // value={ele.id}
                      onChange={(event) => {
                        SetUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p className="capitalize">1 Day</p>
                  </label>
                  {/* 2 Days */}
                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"2 Day"}
                      checked={
                        uncheck_radio_value.checked_value == "2 Day"
                          ? true
                          : false
                      }
                      // value={ele.id}
                      onChange={(event) => {
                        SetUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p className="capitalize">2 Days </p>
                  </label>
                  {/* Next Available Slot */}
                  <label
                    className={
                      bottom_sheet_data.data.p_ele.channel_unavailable.hasOwnProperty(
                        "next_slot"
                      )
                        ? "form-label d-flex align-items-center mb-3 cursor"
                        : "form-label d-flex align-items-center mb-3 cursor border-light-gray"
                    }
                  >
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"Next Available Slot"}
                      disabled={
                        bottom_sheet_data.data.p_ele.channel_unavailable.hasOwnProperty(
                          "next_slot"
                        )
                          ? false
                          : true
                      }
                      checked={
                        uncheck_radio_value.checked_value ==
                        "Next Available Slot"
                          ? true
                          : false
                      }
                      // value={ele.id}
                      onChange={(event) => {
                        SetUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p
                      className={
                        // checked == false
                        bottom_sheet_data.data.p_ele.channel_unavailable.hasOwnProperty(
                          "next_slot"
                        )
                          ? "capitalize"
                          : "capitalize text-light-gray"
                      }
                    >
                      {bottom_sheet_data.data.p_ele.channel_unavailable.hasOwnProperty(
                        "next_slot"
                      )
                        ? "Next Available Slot At " +
                          bottom_sheet_data.data.p_ele.channel_unavailable
                            .next_slot
                        : "Next Available Slot"}
                    </p>
                  </label>
                  {/* Custom Date & Time */}
                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"Custom Date & Time"}
                      // value={ele.id}
                      checked={
                        uncheck_radio_value.checked_value ==
                        "Custom Date & Time"
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        SetUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p className="capitalize">Custom Date & Time</p>
                  </label>

                  {uncheck_radio_value.checked_value == "Custom Date & Time" ? (
                    <div className="px-0 w-50">
                      <input
                        type="datetime-local"
                        className="me-2 form-control"
                        min={new Date().toJSON().slice(0, 10)}
                        onChange={(event: any) => {
                          console.log("date event :", event);
                          let value = event.target.value;
                          let formattedDate = value.split("T");
                          console.log("formattedDate :", formattedDate);
                          var timeSplit = formattedDate[1].split(":");
                          var hours = parseInt(timeSplit[0]);
                          var minutes: any = parseInt(timeSplit[1]);

                          // Determine AM or PM
                          var period = hours >= 12 ? "PM" : "AM";

                          // Convert hours to 12-hour format
                          hours = hours % 12;
                          hours = hours ? hours : 12; // 0 should be 12

                          // Add leading zero to minutes if needed
                          minutes = minutes < 10 ? "0" + minutes : minutes;

                          // Return the formatted time
                          let time_value = hours + ":" + minutes + " " + period;
                          console.log("time_value :", time_value);
                          let other_value = formattedDate[0] + " " + time_value;
                          console.log("other_value :", other_value);
                          SetUncheckRadioValue((preavlue: any) => {
                            // preavlue.checked_value = event.target.name;
                            preavlue.other_value = other_value;
                            return { ...preavlue };
                          });
                        }}
                      />
                    </div>
                  ) : null}
                </div>
                {/* Manualy turn on */}
                <div className="d-flex ">
                  <small className="text-black fw-bold">Manualy turn on</small>
                </div>
                {/* I will turn it on myself */}
                <div className="my-3">
                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"I will turn it on myself"}
                      // value={ele.id}
                      checked={
                        uncheck_radio_value.checked_value ==
                        "I will turn it on myself"
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        SetUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p className="capitalize">I will turn it on myself</p>
                  </label>
                </div>
              </div>
              <div className="p-3">
                <div className="end">
                  <button
                    className="btn btn-primary btn-lg btn-block"
                    onClick={() => {
                      // submit_uncheck_time();
                      console.log("on bottom sheet ok");
                      if (bottom_sheet_data.data.p_ele.type == "product") {
                        let push_value: any = {
                          outlet_channel_product_variants_available: {
                            [bottom_sheet_data.data.p_ele.save_id]: {
                              value: "",
                            },
                          },
                        };
                        SetUncheckRadioValue((radio_prevalue: any) => {
                          if (radio_prevalue.checked_value == "2 Hours") {
                            push_value.outlet_channel_product_variants_available[
                              bottom_sheet_data.data.p_ele.save_id
                            ] = {
                              value: 3,
                              hours: 2,
                            };
                          }
                          if (radio_prevalue.checked_value == "4 Hours") {
                            push_value.outlet_channel_product_variants_available[
                              bottom_sheet_data.data.p_ele.save_id
                            ] = {
                              value: 3,
                              hours: 4,
                            };
                          }
                          if (radio_prevalue.checked_value == "1 Day") {
                            push_value.outlet_channel_product_variants_available[
                              bottom_sheet_data.data.p_ele.save_id
                            ] = {
                              value: 4,
                              days: 1,
                            };
                          }
                          if (radio_prevalue.checked_value == "2 Day") {
                            push_value.outlet_channel_product_variants_available[
                              bottom_sheet_data.data.p_ele.save_id
                            ] = {
                              value: 4,
                              days: 2,
                            };
                          }
                          if (
                            radio_prevalue.checked_value ==
                            "Next Available Slot"
                          ) {
                            push_value.outlet_channel_product_variants_available[
                              bottom_sheet_data.data.p_ele.save_id
                            ] = {
                              value: 6,
                            };
                          }
                          if (
                            radio_prevalue.checked_value == "Custom Date & Time"
                          ) {
                            push_value.outlet_channel_product_variants_available[
                              bottom_sheet_data.data.p_ele.save_id
                            ] = {
                              value: 5,
                              time: radio_prevalue.other_value,
                            };
                          }
                          if (
                            radio_prevalue.checked_value ==
                            "I will turn it on myself"
                          ) {
                            push_value.outlet_channel_product_variants_available[
                              bottom_sheet_data.data.p_ele.save_id
                            ] = {
                              value: 2,
                            };
                          }
                          console.log("push_value :", push_value);
                          available_submit(
                            push_value,
                            bottom_sheet_data.data.p_ele.type,
                            bottom_sheet_data.data.p_ele,
                            bottom_sheet_data.data.p_index,
                            bottom_sheet_data.data.key
                          );
                          return { ...radio_prevalue };
                        });
                      }
                      if (
                        bottom_sheet_data.data.p_ele.type == "product_group"
                      ) {
                        let push_value: any = {
                          outlet_channel_products_available: {
                            [bottom_sheet_data.data.p_ele.save_id]: {
                              value: "",
                            },
                          },
                          // outlet_channel_variants_available: {},
                        };
                        SetUncheckRadioValue((radio_prevalue: any) => {
                          if (radio_prevalue.checked_value == "2 Hours") {
                            push_value.outlet_channel_products_available[
                              bottom_sheet_data.data.p_ele.save_id
                            ] = {
                              value: 3,
                              hours: 2,
                            };
                          }
                          if (radio_prevalue.checked_value == "4 Hours") {
                            push_value.outlet_channel_products_available[
                              bottom_sheet_data.data.p_ele.save_id
                            ] = {
                              value: 3,
                              hours: 4,
                            };
                          }
                          if (radio_prevalue.checked_value == "1 Day") {
                            push_value.outlet_channel_products_available[
                              bottom_sheet_data.data.p_ele.save_id
                            ] = {
                              value: 4,
                              days: 1,
                            };
                          }
                          if (radio_prevalue.checked_value == "2 Day") {
                            push_value.outlet_channel_products_available[
                              bottom_sheet_data.data.p_ele.save_id
                            ] = {
                              value: 4,
                              days: 2,
                            };
                          }
                          if (
                            radio_prevalue.checked_value ==
                            "Next Available Slot"
                          ) {
                            push_value.outlet_channel_products_available[
                              bottom_sheet_data.data.p_ele.save_id
                            ] = {
                              value: 6,
                            };
                          }
                          if (
                            radio_prevalue.checked_value == "Custom Date & Time"
                          ) {
                            push_value.outlet_channel_products_available[
                              bottom_sheet_data.data.p_ele.save_id
                            ] = {
                              value: 5,
                              time: radio_prevalue.other_value,
                            };
                          }
                          if (
                            radio_prevalue.checked_value ==
                            "I will turn it on myself"
                          ) {
                            push_value.outlet_channel_products_available[
                              bottom_sheet_data.data.p_ele.save_id
                            ] = {
                              value: 2,
                            };
                          }
                          // bottom_sheet_data.data.p_ele.product_variants.map(
                          //   (ele: any) => {
                          //     push_value.outlet_channel_variants_available[
                          //       ele.save_id
                          //     ] = false;
                          //   }
                          // );
                          console.log("push_value :", push_value);
                          available_submit(
                            push_value,
                            bottom_sheet_data.data.p_ele.type,
                            bottom_sheet_data.data.p_ele,
                            bottom_sheet_data.data.p_index,
                            bottom_sheet_data.data.key
                          );
                          return { ...radio_prevalue };
                        });
                      }
                    }}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </SwipeableBottomSheet>
        </div>
      ) : null}
    </div>
  );
}
