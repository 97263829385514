import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import bootstrap from '../../../assets/libraries/bootstrap/js/bootstrap'
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { api } from "../../../utils/Api";
import { omit } from "lodash";

const animatedComponents = makeAnimated();

const outlet_initial_value = {

  name: "",
};

let myModal = {};

export default function ModalCreateCollection(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SerIdValue] = useState(useParams());

  const [outlet_data, SetOutletData] = useState(outlet_initial_value);
  const [user_role_options, SetUserRoleOptions] = useState({});
  const [text, setText] = useState("");
  const [page_no, SetPageNo] = useState(1);
  const [user_data, SetUserData] = useState([]);
  const [next_page, SetNextPage] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const [timer, SetTimer] = useState(null);
  const WAIT_INTERVAL = 700;
  const [new_outlet_id, SetNewOutletId] = useState(null);
  const [modal_type, SetModalType] = useState("");
  const [user_errors, SetUserErrors] = useState(false);
  const [errors, setErrors] = useState({});
  const [parent_id, setParentId] = useState("");

  //OutletData End

  useEffect(() => {
    console.log("ModalUser On mount :", props);
    setParentId(props.data);
    let myModal1: HTMLElement | null = document.getElementById("ModalUser");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      SetOutletData(outlet_initial_value);
      setText("");
      SetPageNo(1);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("ModalUser On mount :", props);
      setParentId(props.data);

      myModal = new bootstrap.Modal(document.getElementById("ModalUser"), {});
      myModal.show();
      SetModalType(props.type);

      //   get_users(page_no);

    }
  }, [props.open]);



  //   async function get_users(page_value: any, search_value = null) {
  //     let pass_data = {};
  //     if (search_value != null) {
  //       pass_data = {
  //         get: {
  //           brand: id_value.brand_id,
  //           page: page_value,
  //           search: search_value,
  //         },
  //       };
  //     } else {
  //       pass_data = {
  //         get: {
  //           brand: id_value.brand_id,
  //           page: page_value,
  //         },
  //       };
  //     }
  //     let response = await api("/brand/create_custom_channel", pass_data);
  //     console.log("/brand/get_users_add_brand response :", response);
  //     if (response.response.data) {
  //       SetUserData(response.response.data);
  //     }
  //     SetNextPage(response.response.next_page);
  //   }





  function outlet_value_on_change(name: any, value: any) {

    SetOutletData((oldValues) => ({
      ...oldValues,
      [name]: value,
    }));
  }


  async function add_user() {

    let pass_data = {
      get: {
        brand: id_value.brand_id,
        parent_id: parent_id,


      },
      post: {
        collections: [{ name: outlet_data.name }],

      }
    };
    let data_res = await api("/brand/create_collections", pass_data);
    console.log("add_brand_user response :", data_res.response);
    if (data_res.status_code == 200) {
      let item = close_data;
      item.action = "save";
      item.value = data_res.response.data;
      console.log("Close data : ", item);
      SetCloseData(item);
      SetOutletData(outlet_initial_value);
      myModal.hide();
    }

  }




  return (
    <div
      className="modal fade"
      id="ModalUser"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    // data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-sm modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
              Create Collection
            </h6>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>


          <div className="mt-3 p-3">
            <label className="form-label">Collection Name</label>
            <input
              type="text"
              value={outlet_data.name}
              disabled={props.type == "view" ? true : false}
              className="form-control"
              onChange={(e) => {
                outlet_value_on_change("name", e.target.value);
                SetUserErrors(false);
              }}
            />
            {/* {user_errors ? (
                      <small className="text-red">* Required fields</small>
                    ) : null} */}

          </div>
          <div className="modal-footer">
            {userOpen ? (
              <div>
                <button
                  type="button"
                  className="btn btn-light-outline"
                  onClick={() => {
                    setUserOpen(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div>
                {modal_type == "create" ? (
                  <button
                    type="button"
                    className="btn bg-prime btn-radius text-white btn-sm px-3"
                    onClick={() => {
                      add_user();
                    }}
                  >
                    Add
                  </button>
                ) : modal_type == "edit" ? (
                  <button
                    type="button"
                    className="btn bg-prime btn-radius text-white fw-bold px-3 btn-sm"
                    onClick={() => {
                      update_user();
                    }}
                  >
                    Update
                  </button>
                ) : modal_type == "view" ? (
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm btn-radius px-3"
                    onClick={(event) => {
                      SetCloseData(event);
                      myModal.hide();
                    }}
                  >
                    Close
                  </button>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
