import React, { useState, useEffect, useContext } from "react";
import { api } from "../../../utils/Api";
import { Link, Outlet, useParams } from "react-router-dom";
import { AppConfig } from "../../../config";
import { LazyLoader, ImageLazyLoader } from "../../../utils/LazyLoader/index";

import { AppHelper } from "../../../utils/Helper";
import {
  SwipeableDrawer,
  Dialog,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

import InfiniteScroll from "react-infinite-scroll-component";

import Skeleton from "react-loading-skeleton";

import ModalTime from "../../Outlets/Dashboard/ModalTime";

function Settlement(props: any) {

  const [id_value, SetIdValue] = useState(useParams());
  const [next_page, SetNextPage] = useState(false);
  const [page_no, SetPageNo] = useState(1);
  const [is_loading, SetIsLoading] = useState(false);
  const [outlets, setOutlets] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState("");
  const [filter_data, setFilterData] = useState("");
  const [product_list, setProductList] = useState([]);
  const [visible_content, SetVisibleContent] = useState(false);
  const [base_url, SetBaseUrl] = useState("");
  const [outlet_id, SetOutletId] = useState("");
  const [statisticsView, SetStatisticsView]: any = useState('pastmonth');
  const [statisticsViewFormatted, SetStatisticsViewFormatted]: any = useState('Past Month');
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateShow, setStartDateShow] = useState("");
  const [endDateShow, setEndDateShow] = useState("");
  const [settlementSort, setSettlementSort] = useState("desc");
  const [customDateChangedDate, setCustomDateChangedDate] = useState(1);
  const [statistics, SetStatistics]: any = useState({});
  const [time_modal, setTimeModal] = useState({
    is_open: false,
    data: {},
  });
  useEffect(() => {
    if(!id_value?.outlet_id){
      get_categories("from_top");
    }
  }, [])
  useEffect(() => {
    console.log(props)
    
    // if(statisticsView != 'custom'){
    //   get_statistics();
    // }
    if (statisticsView == 'today') {
      SetStatisticsViewFormatted('Today')
    } else if (statisticsView == 'yesterday') {
      SetStatisticsViewFormatted('Yesterday')
    } else if (statisticsView == 'lastweek') {
      SetStatisticsViewFormatted('Last Week')
    } else if (statisticsView == 'week') {
      SetStatisticsViewFormatted('This Week')
    } else if (statisticsView == 'pastweek') {
      SetStatisticsViewFormatted('Past Week')
    } else if (statisticsView == 'pastmonth') {
      SetStatisticsViewFormatted('Past Month')
    } else if (statisticsView == 'lastmonth') {
      SetStatisticsViewFormatted('Last Month')
    } else if (statisticsView == 'month') {
      SetStatisticsViewFormatted('This Month')
    } else if (statisticsView == 'year') {
      SetStatisticsViewFormatted('This Year')
    } else if (statisticsView == 'lastyear') {
      SetStatisticsViewFormatted('Last Year')
    } else if (statisticsView == 'finyear') {
      SetStatisticsViewFormatted('This Financial Year')
    } else if (statisticsView == 'lastfinyear') {
      SetStatisticsViewFormatted('Last Financial Year')
    } else if (statisticsView == 'custom') {
      SetStatisticsViewFormatted('Custom')
    }
  }, [statisticsView])

  useEffect(() => {
    if(statisticsView || selectedApplication || customDateChangedDate || settlementSort){
      get_statistics();
      let send_data = {
        page_no: 1,
      };
      SetPageNo(1);
      (id_value?.outlet_id &&
      ProductList(send_data)
      )
    }
  }, [statisticsView, selectedApplication, customDateChangedDate || settlementSort])

  // useEffect(() => {
  //   if(settlementSort){
  //     let send_data = {
  //       page_no: 1,
  //     };
  //     SetPageNo(1);
  //     ProductList(send_data);
  //   }
  // }, [settlementSort])
  

  function convertDateTime(dateTimeStr: any) {
    const date = new Date(dateTimeStr);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
    const year = date.getFullYear();

    // const hours = String(date.getHours()).padStart(2, '0');
    // const minutes = String(date.getMinutes()).padStart(2, '0');
    // const seconds = '00'; // Set seconds to 00

    const formattedDateTime = `${day}-${month}-${year} `;
    return formattedDateTime;
  }



  async function get_statistics() {
    let pass_data:any = {
      get: {
        brand: id_value.brand_id,
        view: statisticsView,
        start: startDate,
        end: endDate,
      },
    };
    if(id_value?.outlet_id){
      pass_data.get.outlet = id_value.outlet_id;
    } else {
      pass_data.get.outlet_id = selectedApplication;
    }
    let data: any = await api("/brand/settlement_statistics", pass_data);
    SetStatistics(data.response?.data);
    setStartDate(data.response?.start)
    setEndDate(data.response?.end)
    const convertedDateTime = convertDateTime(data.response?.start_display);




    const convertedEndDateTime = convertDateTime(data.response?.end_display);
    setStartDateShow(convertedDateTime)
    setEndDateShow(convertedEndDateTime)

  }
  async function get_categories(props: any) {

    SetIsLoading(true)
    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
    };

    let response: any = await api("/brand/outlet_list", pass_data);
    if (response.status_code == 200) {
      // setOutlets

      let pass_data;
      if (props == "from_top") {
        pass_data = {
          sort: "",
          page_no: 1,
        };
        SetPageNo(1);
      } else {
        pass_data = {
          sort: "",
          page_no: page_no,
        };
      }
      console.log(response.response.outlets);
      setOutlets(response.response.outlets);
      // SetOutletId(response.response.outlets[0]?.id);
      // setFilterData("");
      ProductList(pass_data);
      SetPageNo(1);

      SetIsLoading(false)
      // SetVisibleContent(true);
    }
  }


  const handleApplicationChange = (event: any) => {
    console.log(event.target.value);
    SetPageNo(1);
    // let send_data = {
    //   page_no: 1,
    // };
    // ProductList(filter_data, send_data);
    SetOutletId(event.target.value);
    setSelectedApplication(event.target.value);
    // let pass_data = {
    //   sort: "",
    //   page_no: 1,
    // };
    // setPayments([]);
    // SetTransactionAble(false)
    // get_categories(pass_data, filter_data,event.target.value);
  };

  async function ProductList(get_data: any) {
    if (!get_data.load_more) {
      SetIsLoading(true)

    }
    // console.log(send_data)
    let pass_data:any = {
      get: {
        brand: id_value.brand_id,
        page: get_data.page_no,
        sort: settlementSort,
        view: statisticsView,
        start: startDate,
        end: endDate,
        // search: search,

      },
    };
    if(id_value?.outlet_id){
      pass_data.get.outlet = id_value.outlet_id;
    } else {
      pass_data.get.outlet_id = selectedApplication;
    }

    let response: any = await api("/brand/settlements", pass_data);

    if (response.status_code == 200) {

      if (response.response.hasOwnProperty("data")) {
        if (get_data.load_more) {
          setProductList((prevValue: any) => {
            response?.response?.data?.map((ele: any) => {
              prevValue.push(ele);
            });
            return [...prevValue];
          });
        } else {
          setProductList(response.response.data);

          // SetOuterData(response.response);

          // disable();
        }
        SetIsLoading(false)
      }
      SetVisibleContent(true);
      if (response.response.hasOwnProperty("next_page")) {
        SetNextPage(response.response.next_page);
        SetPageNo((prevalue) => {
          prevalue = prevalue + 1;
          return prevalue;
        });
      }

      SetBaseUrl(response.response.image_base_url)

    }
  }


  // const sort = (val: any) => {

  //   SetPageNo(1)

  //   let send_data = {
  //     page_no: 1,
  //   };
  //   ProductList(outlet_id, val, send_data);


  // }
  return (
    <div className="font-noto-sans pb-5">
      {/* Header */}
      <div className="head-bar row px-sm-0 px-md-3 pb-sm-2 ">

        <div className="d-lg-flex gap-1 flex-wrap">
          <div >
            <h6 className="align-items-center">Settlement</h6>
          </div>

          {/* <div className="col-md-6 sm-pt-2 end">
       
        <div className="input-icons form-group p-2">
          <input
            type="text"
            className="form-control search-bar p-0"
            placeholder="Search here..."
            value={filter_data}
            onChange={handleInputChange}
          />
          <div className="cursor px-2 border-left">
            <img
              src={
                AppConfig.CDN_Media_url +
                "c241785c497fb98f5f7d4e587b3857ba.svg"
              }
              className="w-20px"
            />
          </div>
        </div>
      </div> */}
          <div className="ms-auto">

            <div className="d-lg-flex gap-1 flex-wrap">

              {/**DropDown */}
              <div className="form-group d-flex align-items-center ps-0 mb-2 mb-lg-0">
                {props.props !== "outlet" &&
                  <select
                    data-show-content="true"
                    className="form-select rounded-0"
                    id="exampleFormControlSelect1"
                    value={selectedApplication}
                    onChange={handleApplicationChange}
                  >
                    <option value="">All</option>
                    {outlets?.map((ele: any, i: number) => (
                      <option key={i} value={ele?.id}>
                        {ele?.name}
                      </option>
                    ))}
                  </select>
                }
              </div>

              {/**Date and Sort in one row */}
              <div className="d-flex align-items-center gap-2 mb-2 mb-lg-0 justify-content-between">
                {/**Date */}
                <div className="d-flex align-items-center border br-1 pr-2 bg-white">
                  <div className="dropdown">
                    <button
                      className="btn bg-white text-muted btn-sm br-4 px-2 d-flex dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <ImageLazyLoader
                        className="img-fluid"
                        width={10}
                        src={AppConfig.CDN_Media_url + "420485dfc5e7bf2426a897a723ad243c.svg"}
                      />
                      <span className="ps-1">{statisticsViewFormatted}</span>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                      <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('today'); }}>Today</button></li>
                      <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('yesterday'); }}>Yesterday</button></li>
                      <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('lastweek'); }}>Last Week</button></li>
                      <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('week'); }}>This Week</button></li>
                      <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('pastweek'); }}>Past Week</button></li>
                      <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('pastmonth'); }}>Past Month</button></li>
                      <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('lastmonth'); }}>Last Month</button></li>
                      <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('month'); }}>This Month</button></li>
                      <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('year'); }}>This Year</button></li>
                      <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('lastyear'); }}>Last Year</button></li>
                      <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('finyear'); }}>This Financial Year</button></li>
                      <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('lastfinyear'); }}>Last Financial Year</button></li>
                      <li><button type="button" className="dropdown-item" onClick={() => { let set_data = { start: startDate, end: endDate }; setTimeModal({ is_open: true, data: set_data }); }}>Custom</button></li>
                    </ul>
                  </div>
                  <div
                    className="text-secondary me-2 fs-12 cursor pr-1"
                    onClick={() => { let set_data = { start: startDate, end: endDate, from: "settlement", outlet: outlet_id }; setTimeModal({ is_open: true, data: set_data }); }}
                  >
                    {startDateShow} - {endDateShow}
                  </div>
                </div>

                {/**Sort */}
                <div className="dropdown ms-2">
                  <button
                    className="btn dropdown-toggle d-flex align-items-center"
                    type="button"
                    id="dropdownMenuButton2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src={require("../../../assets/icon/filter.png")}
                      className="icon-14px"
                    />
                  </button>
                  <ul className="dropdown-menu no-shadow" aria-labelledby="dropdownMenuButton2">
                    <li><button type="button" className="dropdown-item" onClick={() => setSettlementSort("asc")}>Oldest</button></li>
                    <li><button type="button" className="dropdown-item" onClick={() => setSettlementSort("desc")}>Newest</button></li>
                  </ul>
                </div>
              </div>
            </div>



          </div>

        </div>
      </div>

      <div className="mt-3">
        <div className="row">
          <div className="col-6 pe-2 mb-3">
            <div className="box-3 p-3 d-flex flex-column h-100">
              <ImageLazyLoader parentClassName="stat-pic" className="img-fluid mb-3" width={60} src="https://cdn2.1superapp.com/images/e96c21135fb8ef29dc074ef339f23918.png" />
              <div className="fw-500 fs-16 mb-1 mt-auto">
                <div>&#8377;{statistics.total_settlement_amount}</div>
              </div>
              <div className="fw-500 text-grey-1">Total Paid</div>
            </div>
          </div>
          <div className="col-6 mb-3">
            <div className="box-3 p-3 d-flex flex-column h-100">
              <ImageLazyLoader parentClassName="stat-pic" className="img-fluid mb-3" width={60} src="https://cdn2.1superapp.com/images/330ac397130f29b354ca21fa5d8fdead.png" />
              <div className="fw-500 fs-16 mb-1 mt-auto">
                <div>&#8377;{statistics.total_settlement_pending_amount}</div>
              </div>
              <div className="fw-500 text-grey-1">Current Balance</div>
            </div>
          </div>
        </div>
      </div>

      <InfiniteScroll
        dataLength={product_list.length}
        next={() => {
          let send_data = {
            page_no: page_no,

            load_more: true,
          };
          ProductList(send_data);
        }}
        hasMore={next_page}
        loader={
          <div className="center m-5">
            <div className="lds-dual-ring"></div>
            <p className="text-prime small ps-2">Just a second ...</p>
          </div>
        }
      >
        <div className="mt-3">
          {visible_content ?

            <div className="row">
              {product_list.map((ele: any, i: number) => (
                <div key={i} className="col-md-4 mb-2">
                  <div
                    className="card p-3 pb-0"

                  >
                    <div className="d-flex">
                      <div>
                        <div>{ele?.outlet_name}</div>
                        <div>₹ {ele?.amount}</div>

                        <div>{ele?.notes}</div>




                      </div>
                      <div className="ms-auto">
                        <div className="end">#{ele?.id}</div>
                        <div>{ele?.time}</div>
                      </div>


                    </div>

                    <div className="prods-card ">
                      <Accordion className="px-0">
                        <AccordionSummary
                          expandIcon={
                            <img src={AppConfig.CDN_Media_url + "78f45bd73c51ade6a32e1185735e5439.svg"} className="w-10px" />
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <p className="fs-7 fw-bold text-black">
                            Document
                          </p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="w-100">
                            <div className="row">
                              {ele.documents.map((doc_ele: any, i: number) => (
                                <div className="col-4" key={i}>
                                  <a
                                    href={doc_ele?.full_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={doc_ele?.full_url}
                                      className="img-fluid"
                                    />
                                  </a>
                                </div>
                              ))}
                            </div>
                          </div>


                        </AccordionDetails>
                      </Accordion>

                      <Accordion className="px-0">
                        <AccordionSummary
                          expandIcon={
                            <img src={AppConfig.CDN_Media_url + "78f45bd73c51ade6a32e1185735e5439.svg"} className="w-10px" />
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <p className="fs-7 fw-bold text-black">
                            Order List
                          </p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="w-100">
                            {ele?.orders?.map((or_ele: any, i: number) => (
                              <div key={i} className="d-flex justify-content-between">

                                <div >
                                  <div>{or_ele?.customer?.name}</div>

                                  <div >Payable : ₹ {or_ele?.total_amount}</div>

                                  <div>{or_ele?.notes}</div>

                                  {/* <div>{or_ele.customer.or_ele}</div> */}
                                  <br />
                                </div>

                                <div >
                                  <div className="text-end">#{or_ele?.order_item_id}</div>
                                  <div >Paid : ₹ {or_ele?.amount}</div>
                                </div>

                              </div>
                            ))}
                          </div>

                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>

                </div>
              ))}
            </div>


            :
            <div className="row">
              {Array.from({ length: 5 }).map((_, i) => (

                <div key={i} className="col-md-4 mb-2">
                  <div className="card p-3 pb-0">
                    <Skeleton height={30} width={100} />
                    <Skeleton height={20} width={150} />
                    <Skeleton height={20} width={200} />
                    <Skeleton height={20} width={80} />

                    <Skeleton height={30} width={100} />

                  </div>
                </div>

              ))}
            </div>
          }
        </div>

      </InfiniteScroll >

      {statistics != null &&
        <ModalTime
          open={time_modal.is_open}
          data={time_modal.data}
          from="settlement"
          close={(data: any) => {
            console.log("Edit Outlet Modal On Close :", data);
            setTimeModal({
              is_open: false,
              data: {},

            });
            if (data.action == "save") {
              SetStatisticsView("custom")
              setStartDate(data?.startDate)
              setEndDate(data?.endDate)

              // SetStatistics(data.data?.data);


              // setStartDate(data.data?.start)
              // setEndDate(data.data?.end)
              // const convertedDateTime = convertDateTime(data.data?.start_display);

              // SetStatisticsViewFormatted("custom")



              // const convertedEndDateTime = convertDateTime(data.data?.end_display);
              // setStartDateShow(convertedDateTime)
              // setEndDateShow(convertedEndDateTime)

              setCustomDateChangedDate((prev:any) => {
                return prev+1;
              });


            }
          }}
        />
      }
    </div >

  )
}

export default Settlement