import React, { useState, useEffect, useContext,useRef } from "react";
import { Link, useParams, useNavigate, useLocation  } from "react-router-dom";
import DataContext from "../../../utils/Context/DataContext";

import {
  Ionicons,
  Entypo,
  Fontisto,
  AntDesign,
  FontAwesome,
  MaterialCommunityIcons,
  Feather,
  MaterialIcons,
  FontAwesome5,
} from "@expo/vector-icons";
import {
  Dialog,
  Drawer,
  Modal,
  SwipeableDrawer,
  Slide,
} from "@material-ui/core";
import SwipeableBottomSheet from "@geomatico/react-swipeable-bottom-sheet";
// import { Rating } from "@material-ui/lab";
import { api } from "../../../utils/Api";
import toast from "react-hot-toast";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";
import { AppConfig } from "../../../config";

var driver_marker: any;
let counter = 0;
const map_styler = [
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];
var importedScript = document.createElement("script");
let map: any;
let myModal: any;
// let live_tracking_call_interval: any;
// let live_tracking_call_interval1 : any;
// let driver_move_call_interval: any;
let order_details_value = {
  lat: "",
  lng: "",
  delivery_address: {
    lat: "",
    lng: "",
  },
};

let marker_img_url = "https://cdn3.1superapp.com/superapp/deliveryboy.png";

// loadScript(
//   "https://maps.googleapis.com/maps/api/js?key="+AppConfig.google_map_api+"&callback=initMap&v=weekly&libraries=places"
// );

function initMap(): void {
  console.log("initMap :");
  const directionsService = new google.maps.DirectionsService();
  const directionsRenderer = new google.maps.DirectionsRenderer();
  map = new google.maps.Map(
    document.getElementById("map-live-track") as HTMLElement,
    {
      zoom: 12,
      // center: {
      //   lat: parseFloat(props.order_details.outlet_lat),
      //   lng: parseFloat(props.order_details.outlet_lng),
      // },
      center: {
        lat: parseFloat(order_details_value.lat),
        lng: parseFloat(order_details_value.lng),
      },
      mapTypeControl: false,
      fullscreenControl: false,
      zoomControl: false,
      streetViewControl: false,
      mapTypeId: "roadmap",
      gestureHandling: "greedy",
      styles: map_styler,
    }
  );

  directionsRenderer.setMap(map);

  driver_marker = new google.maps.Marker({
    position: null,
    map,
    icon: {
      url: marker_img_url,
      scaledSize: new google.maps.Size(23, 35),
    },
    anchorPoint: new google.maps.Point(0, -29),
  });

  // resturant marker
  new google.maps.Marker({
    // position: {
    //   lat: parseFloat(props.order_details.outlet_lat),
    //   lng: parseFloat(props.order_details.outlet_lng),
    // },
    position: {
      lat: parseFloat(order_details_value.lat),
      lng: parseFloat(order_details_value.lng),
    },
    map: map,
    //   icon: "https://img.icons8.com/external-flaticons-flat-flat-icons/33/external-customer-internet-marketing-service-flaticons-flat-flat-icons-3.png",
    // icon: "https://cdn-icons-png.flaticon.com/32/2499/2499159.png",
    icon: {
      url: "https://cdn3.1superapp.com/superapp/markerpinup.png",
      scaledSize: new google.maps.Size(23, 35),
    },
    anchorPoint: new google.maps.Point(0, -29),
  });
  // customer marker
  new google.maps.Marker({
    // position: {
    //   lat: parseFloat(props.order_details.delivery_address.lat),
    //   lng: parseFloat(props.order_details.delivery_address.lon),
    // },
    position: {
      lat: parseFloat(order_details_value.delivery_address.lat),
      lng: parseFloat(order_details_value.delivery_address.lng),
    },
    map: map,
    //   icon: "https://img.icons8.com/external-flaticons-flat-flat-icons/33/external-customer-internet-marketing-service-flaticons-flat-flat-icons-3.png",
    // icon: "https://cdn-icons-png.flaticon.com/32/70/70770.png",
    icon: {
      url: "https://cdn3.1superapp.com/superapp/markerpindown.png",
      scaledSize: new google.maps.Size(23, 35),
    },
    anchorPoint: new google.maps.Point(0, -29),
  });
}

function loadScript(url: any) {
  var index: any = window.document.getElementsByTagName("script")[0];
  var script = window.document.createElement("script");
  script.id = "google_map";
  script.src = url;
  // script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}

export default function ModalOrderTracking(props: any) {
  const [id_value, SetIdValue] = useState(useParams());
  const context : any = useContext(DataContext);
  const [oldlatlng, SetOldLatLng] = useState([]);
  const [show_prepare_food, SetShowPrepareFood] = useState(true);
  const [show_looking_for_delivery_person, SetShowLookingForDeliveryPerson] =
    useState(true);
  const [show_delivery_person, SetShowDeliveryPerson] = useState(false);
  const [called_live_tracking, SetCalledLiveTracking] = useState(false);
  const [outlet_lat_lng, SetOutletLatLng] = useState({});
  const [page_no, SetPageNo] = useState("");
  const [current_location, SetCurrentLocation] = useState([]);
  const [new_route_called, SetNewRouteCalled] = useState(false);
  const [width, SetWidth] = React.useState(innerWidth);
  const liveTrackingInterval1Ref = useRef<any>(null);
  const liveTrackingInterval2Ref = useRef<any>(null);
  const driverMoveIntervalRef = useRef<any>(null);
  const isMountedRef = useRef(true);
  const navigate = useNavigate();
  const location = useLocation(); 
  useEffect(() => {
    console.log("ModalOrderTracking On mount :", props);
    let myModal1: any = document.getElementById("MapTracker");
    myModal1.addEventListener("hidden.bs.modal", function (event: any) {
      console.log("event :", event);
      clearInterval(liveTrackingInterval1Ref.current);
      clearInterval(liveTrackingInterval2Ref.current);
      clearInterval(driverMoveIntervalRef.current);
      importedScript.remove();
      SetNewRouteCalled(false);
      SetCalledLiveTracking(false);
      isMountedRef.current = false;
    
      props.close();
    });

    return () => {
      isMountedRef.current = false;
      clearAllIntervals();

   
    };
  }, []);
  const clearAllIntervals = () => {
    if (liveTrackingInterval1Ref.current) {
      clearInterval(liveTrackingInterval1Ref.current);
    }
    if (liveTrackingInterval2Ref.current) {
      clearInterval(liveTrackingInterval2Ref.current);
    }
    if (driverMoveIntervalRef.current) {
      clearInterval(driverMoveIntervalRef.current);
    }
    liveTrackingInterval1Ref.current = null;
    liveTrackingInterval2Ref.current = null;
    driverMoveIntervalRef.current = null;
  };

  useEffect(() => {
    if (props.open) {
      isMountedRef.current = true; 
      console.log("props on change ModalOrderTracking component :", props);
      myModal = new bootstrap.Modal(document.getElementById("MapTracker"), {
        backdrop: false,
      });
      //   SetOutletLatLng({
      //     lat: parseFloat(props.order_details.outlet_lat),
      //     lng: parseFloat(props.order_details.outlet_lng),
      //   });
      order_details_value = {
        lat: props.order_details.outlet_lat,
        lng: props.order_details.outlet_lng,
        delivery_address: {
          lat: props.order_details.delivery_address.lat,
          lng: props.order_details.delivery_address.lon,
        },
      };
      process_props_data();
      renderMap();
      myModal.show();
    }


     // Listen for back button press
     const onPopState = () => {
      // Close the modal when back button is pressed
      if (myModal) {
        myModal.hide();
        props.close();
      }
      navigate(location.pathname);
    };

    window.addEventListener('popstate', onPopState);

    return () => {
      window.removeEventListener('popstate', onPopState);
      if (myModal) {
        myModal.hide();
      }
    };
  }, [props.open, navigate, location.pathname]);

  const renderMap = () => {
    loadScript(
      "https://maps.googleapis.com/maps/api/js?key="+AppConfig.google_map_api+"&callback=initMap&v=weekly&libraries=geometry"
    );
    if (!document.getElementById("google_map")) {
      // loadScript(
      //   "https://maps.googleapis.com/maps/api/js?key="+AppConfig.google_map_api+"&callback=initMap&v=weekly&libraries=places"
      // );
      // importedScript.id = "google_map";
      // importedScript.src =
      //   "https://maps.googleapis.com/maps/api/js?key="+AppConfig.google_map_api+"&callback=initMap&v=weekly&libraries=places";
      // document.head.appendChild(importedScript);
      // console.log("importedScript :", importedScript);
    }

    window.initMap = initMap;
  };

  const Spinner = () => <div className="loader-spinner"></div>;

  useEffect(() => {
    console.log("order Details on change props : ", props);
    process_props_data();
    return () => {
      clearAllIntervals();
    };
  }, [props.order_details]);

  function process_props_data() {
    console.log("process_props_data :", props.delivery_index);
    if (
      props.order_details.hasOwnProperty("delivery") &&
      props.order_details.delivery[props.delivery_index]?.hasOwnProperty(
        "hyperlocal_pickup"
      ) &&
      props.open
    ) {
      if (
        Object.keys(
          props.order_details.delivery[props.delivery_index]?.hyperlocal_pickup
        ).length > 0
      ) {
        // SetShowPrepareFood(false);
        SetShowLookingForDeliveryPerson(false);
        SetShowDeliveryPerson(true);

        if (
          props.order_details.delivery[props.delivery_index]?.hyperlocal_pickup
            .status_id > 5
        ) {
          clearInterval(liveTrackingInterval1Ref.current);
      clearInterval(liveTrackingInterval2Ref.current);
          clearInterval(driverMoveIntervalRef.current);
          SetNewRouteCalled((prevalue: any) => {
            if (prevalue == false && prevalue != undefined) {
              let send_data = {
                page: "",
                first_time: true,
                type: "2",
                sector_id: props.order_details.sector_id,
              };
              get_live_tracking(send_data);
              liveTrackingInterval1Ref.current = setInterval(function () {
                console.log("set interval call1 10k :")
                SetPageNo((prevalue1: any) => {
                  let send_data = {
                    page: prevalue1,
                    first_time: false,
                    type: "2",
                    sector_id: props.order_details.sector_id,
                  };
                  get_live_tracking(send_data);
                  return prevalue1;
                });
              }, 10000);
              driverMoveIntervalRef.current = setInterval(function () {
                driver_move(
                  props.order_details.delivery_address.lat,
                  props.order_details.delivery_address.lon
                );
              }, 1000);
            }
            return prevalue;
          });
        } else {
          SetCalledLiveTracking((prevalue: any) => {
            if (!prevalue) {
              let send_data = {
                page: "",
                first_time: true,
                type: "1",
                sector_id: props.order_details.sector_id,
              };
              get_live_tracking(send_data);
              liveTrackingInterval2Ref.current = setInterval(function () {
                console.log("set interval call2 10k :")
                SetPageNo((prevalue1: any) => {
                  let send_data = {
                    page: prevalue1,
                    first_time: false,
                    type: "1",
                    sector_id: props.order_details.sector_id,
                  };
                  get_live_tracking(send_data);
                  return prevalue1;
                });
              }, 10000);
              driverMoveIntervalRef.current = setInterval(function () {
                driver_move(
                  props.order_details.delivery_address.lat,
                  props.order_details.delivery_address.lon
                );
              }, 1000);
            }
            return prevalue;
          });
        }
      }

      if (
        props.order_details.delivery[props.delivery_index]?.status_id == 3 ||
        props.order_details.delivery[props.delivery_index]?.status_id == 4
      ) {
        // accepted
        SetShowPrepareFood(true);
      } else {
        SetShowPrepareFood(false);
      }
    }
  }

  async function get_live_tracking(get_data: any) {
    console.log("get_live_tracking get_data :",get_data)
    console.log("isMounted :",isMountedRef.current)

    if (!isMountedRef.current) return;
    let pass_data = {
      get: {
        // order_id: props.order_details.id,
        // delivery_id: props.delivery_id,
        // page: get_data.page,
        brand: props.brand_id,
        outlet: props.outlet_id,
        delivery_id: props.delivery_id,
        page: get_data.page,
      },
    };
    console.log("get_live_tracking pass_data :", pass_data);
    let response = await api(
      "/outlet/live_tracking",
      pass_data,
      false,
      false,
      "form_data",
      false
    );
    console.log("get_live_tracking response :", response);
    if (response.status_code == 200) {
      if (!isMountedRef.current) return;

      SetCalledLiveTracking(true);
      SetNewRouteCalled(true);

      if (response.response.hasOwnProperty("location")) {
        let page_no_value =
          response.response.location[response.response.location.length - 1]
            .pageno;
        console.log("get_live_tracking response page no  :", page_no_value);
        SetPageNo(page_no_value);
        SetCurrentLocation((prevalue: any) => {
          for (var i = 0; i < response.response.location.length; i++) {
            prevalue.push(response.response.location[i]);
          }
          console.log("SetCurrentLocation prevalue :", prevalue);
          return [...prevalue];
        });
        // SetCalledLiveTracking(true);
        if (get_data.first_time) {
          if (get_data.type == 2) {
            console.log("type 2");
            let send_data: any;
            if (get_data.sector_id == 4) {
              send_data = {
                customer_location: {
                  lat: parseFloat(props.order_details.delivery_address.lat),
                  lng: parseFloat(props.order_details.delivery_address.lon),
                },
                restaurant_location: {
                  lat: parseFloat(props.order_details.pickup_address.lat),
                  lng: parseFloat(props.order_details.pickup_address.lon),
                },
                delivery_person_location: {
                  lat: parseFloat(response.response.location[0].lat),
                  lng: parseFloat(response.response.location[0].lng),
                },
              };
            } else {
              send_data = {
                restaurant_location: {
                  lat: parseFloat(props.order_details.delivery_address.lat),
                  lng: parseFloat(props.order_details.delivery_address.lon),
                },
                customer_location: {
                  lat: parseFloat(props.order_details.pickup_address.lat),
                  lng: parseFloat(props.order_details.pickup_address.lon),
                },
                delivery_person_location: {
                  lat: parseFloat(response.response.location[0].lat),
                  lng: parseFloat(response.response.location[0].lng),
                },
              };
            }
            console.log(
              "calling showNewDirections from type 2 get_live_tracking ",
              get_data
            );
            showNewDirections(send_data);
            // SetNewRouteCalled(true);
          }

          if (get_data.type == 1) {
            let send_data: any;
            if (get_data.sector_id == 4) {
              send_data = {
                customer_location: {
                  lat: parseFloat(props.order_details.pickup_address.lat),
                  lng: parseFloat(props.order_details.pickup_address.lon),
                },
                restaurant_location: {
                  lat: parseFloat(props.order_details.outlet_lat),
                  lng: parseFloat(props.order_details.outlet_lng),
                },
                delivery_person_location: {
                  lat: parseFloat(response.response.location[0].lat),
                  lng: parseFloat(response.response.location[0].lng),
                },
              };
            } else {
              send_data = {
                customer_location: {
                  // lat: parseFloat(props.order_details.delivery_address.lat),
                  // lng: parseFloat(props.order_details.delivery_address.lon),
                  lat: parseFloat(props.order_details.outlet_lat),
                  lng: parseFloat(props.order_details.outlet_lng),
                },
                restaurant_location: {
                  lat: parseFloat(props.order_details.outlet_lat),
                  lng: parseFloat(props.order_details.outlet_lng),
                },
                delivery_person_location: {
                  lat: parseFloat(response.response.location[0].lat),
                  lng: parseFloat(response.response.location[0].lng),
                },
              };
            }

            showDirections(send_data);
          }

          driver_move(
            props.order_details.delivery_address.lat,
            props.order_details.delivery_address.lon
          );
        }
      }
    }
  }

  function showDirections(get_data: any) {
    console.log("showDirections");
    var directionsService = new google.maps.DirectionsService();
    var directionsRenderer = new google.maps.DirectionsRenderer({
      map: map,
      suppressMarkers: true,
    });

    // Calculate the route between the two locations
    directionsService?.route(
      {
        origin: get_data.delivery_person_location,
        destination: get_data.customer_location,
        // waypoints: [
        //   { location: get_data.restaurant_location, stopover: false },
        // ],
        travelMode: google.maps.TravelMode.DRIVING,
      },
      function (response, status) {
        if (status == "OK") {
          // Display the route on the map
          directionsRenderer.setDirections(response);
          map.setZoom(14);
        } else {
          // Handle errors
          console.error(status);
        }
      }
    );
  }

  function showNewDirections(get_data: any) {
    console.log("showNewDirections");
    var directionsService = new google.maps.DirectionsService();
    var directionsRenderer = new google.maps.DirectionsRenderer({
      map: map,
      suppressMarkers: true,
    });

    // Calculate the route between the two locations
    directionsService?.route(
      {
        origin: get_data.delivery_person_location,
        destination: get_data.customer_location,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      function (response, status) {
        if (status == "OK") {
          // Display the route on the map
          directionsRenderer.setDirections(response);
          map.setZoom(14);
        } else {
          // Handle errors
          console.error(status);
        }
      }
    );
  }

  function driver_move(lat: any, lon: any) {
    // console.log("driver move");
    SetCurrentLocation((prevalue: any) => {
      const start = new google.maps.LatLng(
        parseFloat(oldlatlng[oldlatlng.length - 1]?.lat),
        parseFloat(oldlatlng[oldlatlng.length - 1]?.lng)
      );
      const end = new google.maps.LatLng(
        parseFloat(prevalue[0]?.lat),
        parseFloat(prevalue[0]?.lng)
      );

      const heading = google.maps.geometry.spherical.computeHeading(start, end);
      const heading_api = prevalue[0]?.heading;

      // old start

      // var car =
      //   "M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z";
      // var icon = {
      //   path: car,
      //   scale: 0.7,
      //   strokeColor: "white",
      //   strokeWeight: 0.1,
      //   fillOpacity: 1,
      //   fillColor: "#404040",
      //   offset: "5%",
      //   // rotation: parseInt(heading[i]),
      //   rotation: parseInt(heading_api),
      //   anchor: new google.maps.Point(10, 25), // orig 10,50 back of car, 10,0 front of car, 10,25 center of car
      // };

      // driver_marker.setIcon(icon);

      // old end

      // 10/06/2023
      let deg = parseInt(heading_api); // based on api heading value
      let doc_var: any = document.querySelector(
        'img[src="' + marker_img_url + '"]'
      );
      if (doc_var != null) {
        doc_var.style.transform = "rotate(" + deg + "deg)";
        doc_var.parentElement.style.overflow = "inherit";
      }

      const newPosition = new google.maps.LatLng(
        parseFloat(prevalue[0]?.lat),
        parseFloat(prevalue[0]?.lng)
      );
      // console.log("current_location prevalue :", prevalue);
      // console.log("newPosition.lat :", newPosition.lat());
      // console.log("newPosition.lng :", newPosition.lng());

      // Set the marker's position to the new position
      driver_marker.setPosition(newPosition);

      if (prevalue.length > 1) {
        let old = prevalue.shift();

        oldlatlng.push(old);
        SetOldLatLng(oldlatlng);
        // setCurrentLocation(prevalue);
        // console.log("Current location: " + current_location)
      }
      return [...prevalue];
    });
    // const start = new google.maps.LatLng(
    //   parseFloat(oldlatlng[oldlatlng.length - 1]?.lat),
    //   parseFloat(oldlatlng[oldlatlng.length - 1]?.lng)
    // );
    // const end = new google.maps.LatLng(
    //   parseFloat(current_location[0]?.lat),
    //   parseFloat(current_location[0]?.lng)
    // );

    // const heading = google.maps.geometry.spherical.computeHeading(start, end);
    // const heading_api = current_location[0]?.heading;

    // var car =
    //   "M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z";
    // var icon = {
    //   path: car,
    //   scale: 0.7,
    //   strokeColor: "white",
    //   strokeWeight: 0.1,
    //   fillOpacity: 1,
    //   fillColor: "#404040",
    //   offset: "5%",
    //   // rotation: parseInt(heading[i]),
    //   rotation: parseInt(heading_api),
    //   anchor: new google.maps.Point(10, 25), // orig 10,50 back of car, 10,0 front of car, 10,25 center of car
    // };

    // driver_marker.setIcon(icon);
    // const newPosition = new google.maps.LatLng(
    //   parseFloat(current_location[0]?.lat),
    //   parseFloat(current_location[0]?.lng)
    // );
    // console.log("current_location :", current_location);
    // console.log("newPosition.lat :", newPosition.lat());
    // console.log("newPosition.lng :", newPosition.lng());

    // // Set the marker's position to the new position
    // driver_marker.setPosition(newPosition);

    // if (current_location.length > 1) {
    //   let old = current_location.shift();

    //   oldlatlng.push(old);
    //   SetOldLatLng(oldlatlng);
    //   setCurrentLocation(current_location);
    //   // console.log("Current location: " + current_location)
    // }
  }

  async function cus_number() {
    let pass_data = {
      get: {
        brand: props.brand_id,
        outlet: props.outlet_id,
        order_id: props.order_details.id,
        callto: "customer",
       
      },
    };
    let data_res:any = await api("/outlet/call", pass_data);
    if (data_res.status_code = 200) {
      if (data_res?.response?.phone_number) {
        if (context?.app_data.application?.platform == "android") {
          window.open("tel:" + data_res?.response?.phone_number);
        } else {
          window.location.href = "tel:" + data_res?.response?.phone_number;
        }
      }
    }
  }
  async function del_partner_number() {
    let pass_data = {
      get: {
        brand: props.brand_id,
        outlet: props.outlet_id,
        order_id: props.order_details.id,
        callto: "delivery_partner",
        delivery_id:  props.order_details?.delivery[props.delivery_index]
        ?.hyperlocal_pickup?.delivery_partner_mobile_delivery_id
      },
    };
    let data_res:any = await api("/outlet/call", pass_data);
    if (data_res.status_code = 200) {
      // if(data_res?.response?.phone_number){
      //   window.open('tel:'+data_res?.response?.phone_number);
      // }
      if (data_res?.response?.phone_number) {
        if (context?.app_data.application?.platform == "android") {
          window.open("tel:" + data_res?.response?.phone_number);
        } else {
          window.location.href = "tel:" + data_res?.response?.phone_number;
        }
      }
    }
  }

  const StatusPanel = () => {
    return (
      <>
        {props.order_details.hasOwnProperty("delivery") &&
        props.order_details?.delivery.length > 0 ? (
          <>
            {/* Delivery Time */}
            {/* <div className="d-flex align-items-cetner px-2">
              <h6 className="mb-0">Delivery in 25 mins</h6>
              <div className="ms-auto">
                <p className="mb-0 px-2 py-1 text-green bg-light-custom-green  fw-bold radius">
                  ON TIME
                </p>
              </div>
            </div> */}
            <div className="d-flex align-items-cetner px-2">
              <h6 className="mb-0">Delivery to</h6>
            </div>
            {/* Delivery Address */}
            <div className="px-2 py-3">
              <div className="d-flex align-items-center">
                {props.order_details?.delivery_address?.type == "Home" ? (
                  <div className="pe-2">
                    <Ionicons name="home-outline" size={16} color="#ccc" />
                  </div>
                ) : null}
                {props.order_details?.delivery_address?.type == "Work" ? (
                  <div className="pe-2">
                    <FontAwesome5 name="building" size={16} color="#ccc" />
                  </div>
                ) : null}
                {props.order_details?.delivery_address?.type == "Others" ? (
                  <div className="pe-2">
                    <Ionicons
                      name="md-location-outline"
                      size={16}
                      color="#ccc"
                    />
                  </div>
                ) : null}

                <p className="text-dark fs-7">
                  {props.order_details?.delivery_address?.name}
                </p>
              </div>

              {props.order_details?.delivery_address?.map_address != "" ? (
                <p className="text-gray v-small pt-2">
                  {props.order_details?.delivery_address?.map_address}
                </p>
              ) : (
                <p className="text-gray v-small pt-2">
                  {props.order_details?.delivery_address?.door_no},
                  {props.order_details?.delivery_address?.address},
                  {props.order_details?.delivery_address?.city},
                  {props.order_details?.delivery_address?.state},
                  {props.order_details?.delivery_address?.country},
                  {props.order_details?.delivery_address?.pincode}
                </p>
              )}
            </div>
            {/* Loading bar */}
            {/* <div className="py-3 px-2">
              <div className="progress">
                <div
                  className={"progress-bar w-50 bg-prime1"}
                  // className={"progress-bar w-25 bg-prime1"}
                ></div>
              </div>
            </div> */}

            <div className="p-2">
              <div className="border p-2 radius">
                {/* resturant preparing food */}
                {/* {show_prepare_food ? (
                  <div className="d-flex pb-3 border-bottom">
                    <div className=" border radius p-0">
                      <img
                        src={require("../../../assets/img/food8.jpg")}
                        className="delivery-img"
                      />
                    </div>
                    <div className=" px-2 d-flex align-items-center w-100">
                      <p className="text-darkgray">
                        <b className="text-dark">
                          {props.order_details?.brand_name}
                        </b>{" "}
                        {
                          props.order_details?.delivery[props.delivery_index]
                            ?.status
                        }
                      </p>
                      <div className="py-2 px-3 cursor center ms-auto">
                        <a href={"tel:" + props.order_details?.outlet_mobile}>
                          <img
                            src={require("../../../assets/icon/phone.png")}
                            className="bank-img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                ) : null} */}

                {/* customer details */}
                <div className="d-flex pb-3 border-bottom">
                  <div className=" border radius p-0">
                    {props.order_details?.hasOwnProperty("customer_dp") &&
                    props.order_details?.customer_dp != null ? (
                      <img
                        src={props.order_details?.customer_dp}
                        className="delivery-img"
                      />
                    ) : (
                      <img
                        src={require("../../../assets/img/12.jpg")}
                        className="delivery-img"
                      />
                    )}
                  </div>
                  <div className=" px-2 d-flex align-items-center w-100">
                    <p className="text-darkgray">
                      <b className="text-dark">
                        {props.order_details?.customer_name}
                      </b>
                    </p>
                    <div className="py-2 px-3 cursor center ms-auto">
                      {props.order_details?.hasOwnProperty(
                        "customer_mobile"
                      ) ? (
                         props.order_details?.customer_mobile &&
                          <img
                          onClick={() => {cus_number()}}
                            src={require("../../../assets/icon/phone.png")}
                            className="bank-img"
                          />
                        
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* looking for delivery person */}
                {show_looking_for_delivery_person ? (
                  <div className="d-flex pt-3 align-items-center">
                    <div className="border radius p-0 ">
                      <div className="delivery-img center">
                        <FontAwesome5 name="user" size={16} color="#555" />
                      </div>
                    </div>
                    <div className="px-2">
                      <div className="d-flex align-items-center">
                        <p className="text-darkgray">
                          We are looking for a delivery partner to pick your
                          order.
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}

                {/* delivery person on the way to pickup order */}
                {show_delivery_person ? (
                  <div className="d-flex pt-3">
                    <div className="border radius p-0">
                      {props.order_details?.delivery[props.delivery_index]
                        ?.hyperlocal_pickup?.delivery_partner_dp != null &&
                      props.order_details?.delivery[props.delivery_index]
                        ?.hyperlocal_pickup?.delivery_partner_dp != "" ? (
                        <img
                          src={
                            props.order_details?.delivery[props.delivery_index]
                              ?.hyperlocal_pickup?.delivery_partner_dp
                          }
                          className="delivery-img"
                        />
                      ) : // <img
                      //   src={require("../../../assets/img/prof.jpg")}
                      //   className="delivery-img"
                      // />
                      null}
                      {/* <img
                        src={require("../../../assets/img/prof.jpg")}
                        className="delivery-img"
                      /> */}
                    </div>
                    <div className="px-2 d-flex align-items-center w-100">
                      <p className="text-darkgray">
                        <b className="text-dark fs-7">
                          {
                            props.order_details?.delivery[props.delivery_index]
                              ?.hyperlocal_pickup?.delivery_partner_name
                          }
                        </b>{" "}
                        {
                          props.order_details?.delivery[props.delivery_index]
                            ?.hyperlocal_pickup?.status
                        }
                      </p>
                      <div className="py-2 px-3 cursor center ms-auto">
                        {
                           
                            props.order_details?.delivery[props.delivery_index]
                              ?.hyperlocal_pickup?.delivery_partner_mobile &&
                        
                      
                          <img
                          onClick={() => del_partner_number()}
                            src={require("../../../assets/icon/phone.png")}
                            className="bank-img"
                          />
                        }
                       
                      </div>
                    </div>
                  </div>
                ) : null}

                {/* delivery person on the way to delivery */}
                {/* {show_on_the_way_delivery ? (
                  <div>
                    <div className="d-flex pt-3 align-items-center">
                      <div className="border radius p-0">
                        <img
                          src={require("../../../assets/img/prof.jpg")}
                          className="delivery-img"
                        />
                      </div>
                      <div className="px-2 d-flex align-items-center w-100">
                        <p className="text-darkgray">
                          <b className="text-dark fs-7">
                            {
                              props.order_details?.delivery[props.delivery_index]
                                ?.hyperlocal_pickup?.delivery_partner_name
                            }
                          </b>{" "}
                          {
                            props.order_details?.delivery[props.delivery_index]
                              ?.hyperlocal_pickup?.status
                          }
                        </p>
                        <div className="px-3 py-2 cursor center ms-auto">
                          <img
                            src={require("../../../assets/icon/phone.png")}
                            className="bank-img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null} */}

                {/* {show_reached_location ? (
                  <div>
                    <div className="d-flex pt-3 align-items-center">
                      <div className="border radius p-0">
                        <img
                          src={require("../../../assets/img/prof.jpg")}
                          className="delivery-img"
                        />
                      </div>
                      <div className="px-2 d-flex align-items-center w-100">
                        <p className="text-darkgray">
                          <b className="text-dark fs-7">
                            {
                              props.order_details?.delivery[props.delivery_index]
                                ?.hyperlocal_pickup?.delivery_partner_name
                            }
                          </b>{" "}
                          {
                            props.order_details?.delivery[props.delivery_index]
                              ?.hyperlocal_pickup?.status
                          }
                        </p>
                        <div className="px-3 py-2 cursor center ms-auto">
                          <img
                            src={require("../../../assets/icon/phone.png")}
                            className="bank-img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null} */}

                {/* {show_paymet_collected ? (
                  <div>
                    <div className="d-flex pt-3 align-items-center">
                      <div className="border radius p-0">
                        <img
                          src={require("../../../assets/img/prof.jpg")}
                          className="delivery-img"
                        />
                      </div>
                      <div className="px-2 d-flex align-items-center w-100">
                        <p className="text-darkgray">
                          <b className="text-dark fs-7">
                            {props.order_details?.delivery_person?.name}
                            {
                              props.order_details?.delivery[props.delivery_index]
                                ?.hyperlocal_pickup?.delivery_partner_name
                            }
                          </b>{" "}
                          {
                            props.order_details?.delivery[props.delivery_index]
                              ?.hyperlocal_pickup?.status
                          }
                        </p>
                        <div className="px-3 py-2 cursor center ms-auto">
                          <img
                            src={require("../../../assets/icon/phone.png")}
                            className="bank-img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null} */}

                {/* {show_delivery_completed ? (
                  <div>
                    <div className="d-flex py-2">
                      <div className="px-2">
                        <div className="d-flex align-items-center">
                          <p className="text-green fs-7 fw-bold">
                            Delivery Completed!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null} */}

                {/* {show_order_completed ? (
                  <div>
                    <div className="d-flex py-2">
                      <div className="px-2">
                        <div className="d-flex align-items-center">
                          <p className="text-green fs-7 fw-bold">
                            Order Completed!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null} */}
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  };

  function get_mins(totalSeconds: any) {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const result = `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
    return result;
  }

  function padTo2Digits(num: any) {
    return num.toString().padStart(2, "0");
  }

  return (
    <div
      className="modal fade"
      id="MapTracker"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="">
        {props?.order_details?.hasOwnProperty("delivery") &&
        props.order_details?.delivery.length > 0 ? (
          <div className="modal-dialog  modal-fullscreen" data-backdrop="false">
            <div className="modal-content">
              {/* header */}
              <div className="modal-header map-header">
                {/* title */}
                <div className="ps-1 pe-0 w-100">
                  <p
                    className="fs-7 text-dark mb-0 fw-500"
                    onClick={() => {
                      console.log("props :", props);
                    }}
                  >
                    Order ID: #{props.order_details?.id}
                  </p>

                  <div className="d-flex align-items-center ">
                    <p className="text-gray pe-1">
                      {/* 06:45 pm |  */}
                      {props.order_details?.products?.length} Item | ₹
                      {props.order_details?.amount}
                    </p>
                  </div>
                </div>
                {/* help */}
                {/* <div className="ms-auto d-flex align-items-center">
                <div className="d-flex align-items-center me-2">
                    <AntDesign name="customerservice" size={18} color="black" />
                    <p className="text-black fw-bold  ps-1 cursor">HELP</p>
                </div>
                <div className="p-1 cursor">
                    <AntDesign name="infocirlceo" size={20} color="#007dae" />
                </div>
                </div> */}
                {/* {!can_save && (<Spinner/>)}     */}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body p-0">
                <div className="row p-0 ">
                  {/* map start */}
                  <div className="col-md-7 col-12 p-0">
                    <div id="map-live-track"></div>
                  </div>
                  {/* status panel desktop only */}
                  {width > 720 ? (
                    // sm-none md-block
                    <div className="col-md-5 pt-3 bg-fff">
                      <StatusPanel />
                    </div>
                  ) : null}

                  {/* status panel mobile only */}
                  {width < 720 ? (
                    // sm-block md-none
                    <div className="map-over center  bg-fff">
                      <SwipeableBottomSheet overflowHeight={250}>
                        <div className="p-2 bg-fff sticky-top custom-swipe-shadow">
                          <div className="center pb-3">
                            <hr className="hr-drawer" />
                          </div>

                          <StatusPanel />
                        </div>
                      </SwipeableBottomSheet>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
